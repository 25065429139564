import {
  //IconButton, //switch light/dark mode
  Typography,
  useTheme,
  Button,
} from "@mui/material";

import {
  //DarkModeOutlined, //switch light/dark mode
  //LightModeOutlined, //switch light/dark mode
  Login,
  Dashboard,
} from "@mui/icons-material";

import {
  //useDispatch, //dispatch per switch light/dark mode
  useSelector,
} from "react-redux";

//import { setMode } from "state/redux/mode/modeSlice"; //switch light/dark mode

import { Link } from "react-router-dom";
import FlexBetween from "components/FlexBetween";

function PublicNavBar() {
  const { userInfo } = useSelector((state) => state.auth);

  //dispatch per switch light/dark mode */
  // const dispatch = useDispatch();

  const theme = useTheme();

  return (
    <FlexBetween padding="1rem 6%">
      <Typography
        variant="h4"
        fontWeight="bold"
        component={Link}
        sx={{
          textDecoration: "none",
          color: theme.palette.secondary.main,
        }}
        to="/"
      >
        PRB-Project
      </Typography>

      <FlexBetween gap="1.75rem">
        {/* Bottone per switch light/dark mode */}
        {/* <IconButton
          onClick={() => dispatch(setMode())}
          sx={{ fontSize: "25px" }}
        >
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlined sx={{ fontSize: "25px" }} />
          ) : (
            <LightModeOutlined sx={{ fontSize: "25px" }} />
          )}
        </IconButton> */}

        {userInfo ? (
          <Button
            component={Link}
            key="dashboard"
            startIcon={<Dashboard />}
            sx={{ ml: 2 }}
            to="/dash"
            color="primary"
            variant="contained"
          >
            DASHBOARD
          </Button>
        ) : (
          <Button
            component={Link}
            key="login-register"
            startIcon={<Login />}
            sx={{ ml: 2 }}
            to="/auth"
            color="primary"
            variant="contained"
          >
            LOGIN/REGISTER
          </Button>
        )}
      </FlexBetween>
    </FlexBetween>
  );
}

export default PublicNavBar;
