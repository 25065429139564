import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Dialog from "@mui/material/Dialog";
import { FormControlLabel, Checkbox, Switch } from "@mui/material";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme, Stack } from "@mui/material";

import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

import {
  useAddDispoMutation,
  useUpdateDispoMutation,
} from "state/redux/dispo/dispoApiSlice";
import { useGetSediQuery } from "state/redux/sede/sedeApiSlice";

import { toast } from "react-toastify";
import FlexBetween from "components/FlexBetween";

const addDispoSchema = yup.object({
  mac: yup
    .string("Inserisci un Mac Address")
    .required("Il Mac Address è richiesto"),
  codifica: yup
    .string("Inserisci la codifica")
    .required("La Codifica è richiesta"),
});

export default function DispoFormDialog({
  openForm,
  handleCloseForm,
  modeForm,
  dispoForm,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [addDispo, { isLoading: isLoadingAdd }] = useAddDispoMutation();
  const [updateDispo, { isLoading: isLoadingUpdate }] =
    useUpdateDispoMutation();

  const { data: dataSedi, isLoading: isLoadingSedi } = useGetSediQuery();

  let initialValues = {};
  if (modeForm === "add") {
    initialValues = {
      mac: "",
      codifica: "",
      sede: "",
      unita_misurata: "",
      unitaList: [],
      enabled: true,
    };
  } else {
    initialValues = {
      mac: dispoForm.mac,
      _id: dispoForm._id,
      codifica: dispoForm.codifica,
      sede: dispoForm.info_asset?.codifica ?? "",
      unita_misurata: dispoForm.info_asset?.unitaMisurate._id ?? "",
      unitaList:
        dataSedi &&
        dispoForm.info_asset?.codifica &&
        dataSedi.find((sede) => {
          return sede.codifica === dispoForm.info_asset?.codifica;
        }).unitaMisurate,
      enabled: dispoForm.enabled,
    };
  }

  const handleAddDispo = async (values) => {
    try {
      const res = await addDispo({
        mac: values.mac,
        codifica: values.codifica,
        unita_misurata: values.unita_misurata,
      }).unwrap();
      toast.success(`dispositivo ${res.dispo.codifica} aggiunto`, {
        position: "top-center",
        autoClose: 1000,
      });
      navigate("/dash/dispo");
      handleCloseForm();
    } catch (err) {
      toast.error(err?.data?.message || err.error, { position: "top-center" });
    }
  };

  const handleUpdateDispo = async (values) => {
    try {
      console.log(values);
      const res = await updateDispo({
        _id: values._id,
        mac: values.mac,
        codifica: values.codifica,
        unita_misurata: values.unita_misurata,
        enabled: values.enabled,
      }).unwrap();
      toast.success(`dispositivo ${res.dispo.codifica} modificato`, {
        position: "top-center",
        autoClose: 1000,
      });
      handleCloseForm();
      navigate("/dash/dispo");
    } catch (err) {
      toast.error(err?.data?.message || err.error, { position: "top-center" });
    }
  };

  const handleFormSubmit = async (values) => {
    if (modeForm === "add") await handleAddDispo(values);
    if (modeForm === "update") await handleUpdateDispo(values);
  };

  return (
    <Dialog open={openForm} onClose={handleCloseForm} fullWidth>
      <DialogTitle
        style={{
          backgroundColor: theme.palette.background.alt,
        }}
      >
        {modeForm === "add"
          ? "Aggiungi un dispositivo"
          : "Modifica il dispositivo"}
      </DialogTitle>
      <DialogContent
        style={{
          backgroundColor: theme.palette.background.alt,
        }}
      >
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={addDispoSchema}
        >
          {({
            values,
            isValid,
            dirty,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            resetForm,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Stack spacing={2} mt={2} mb={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="enabled"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      checked={values.enabled}
                    />
                  }
                  label="Abilitato"
                />
                <TextField
                  label="Mac Address"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.mac}
                  name="mac"
                  error={Boolean(touched.mac) && Boolean(errors.mac)}
                  helperText={touched.mac && errors.mac}
                  fullWidth
                />
                <TextField
                  label="Codifica"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.codifica}
                  name="codifica"
                  error={Boolean(touched.codifica) && Boolean(errors.codifica)}
                  helperText={touched.codifica && errors.codifica}
                  fullWidth
                />
                <FlexBetween gap="1rem">
                  <TextField
                    label="Sede"
                    type="text"
                    name="sede"
                    select
                    onBlur={handleBlur}
                    value={values.sede}
                    onChange={async (e) => {
                      const { value } = e.target;
                      let _unitaList = [];
                      if (value !== "") {
                        _unitaList = dataSedi.find((sede) => {
                          return sede.codifica === value;
                        }).unitaMisurate;
                      }
                      await setFieldValue("sede", value);
                      await setFieldValue("unita_misurata", "");
                      await setFieldValue("unitaList", _unitaList);
                    }}
                    error={Boolean(touched.sede) && Boolean(errors.sede)}
                    helperText={touched.sede && errors.sede}
                    fullWidth
                  >
                    <MenuItem value={""} key={"null_option"}>
                      <em>Selezionare la sede</em>
                    </MenuItem>
                    {dataSedi &&
                      dataSedi.map((sede) => (
                        <MenuItem value={sede.codifica} key={sede._id}>
                          {sede.codifica}
                        </MenuItem>
                      ))}
                  </TextField>

                  <TextField
                    label="Unità Misurata"
                    type="text"
                    select
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.unita_misurata}
                    name="unita_misurata"
                    error={
                      Boolean(touched.unita_misurata) &&
                      Boolean(errors.unita_misurata)
                    }
                    helperText={touched.unita_misurata && errors.unita_misurata}
                    fullWidth
                  >
                    <MenuItem value={""} key={"null_option"}>
                      <em>Seleziona l'Unità</em>
                    </MenuItem>
                    {values.unitaList &&
                      values.unitaList.map((unita) => (
                        <MenuItem value={unita._id} key={unita._id}>
                          {unita.codifica}
                        </MenuItem>
                      ))}
                  </TextField>
                </FlexBetween>

                {/* <FormControl fullWidth>
                  <InputLabel id="select-label">Sede</InputLabel>
                  <Select
                    name="sede"
                    value={values.sede}
                    label="Sede"
                    onChange={handleChange}
                  >
                    {dataSedi &&
                      dataSedi.map((sede) => (
                        <MenuItem value={sede.codifica} key={sede._id}>
                          {sede.codifica}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl> */}
              </Stack>

              <Button
                onClick={handleCloseForm}
                variant="contained"
                sx={{
                  m: 2,
                  "&:hover": { color: theme.palette.primary.main },
                }}
              >
                Annulla
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={!(isValid && dirty)}
                sx={{
                  m: 2,
                  "&:hover": { color: theme.palette.primary.main },
                }}
              >
                Salva
              </Button>
              <Button
                onClick={() => {
                  console.log("values: ", values);
                  console.log("dirty: ", dirty);
                  console.log("isValid: ", isValid);
                }}
                variant="contained"
                sx={{ m: 2 }}
              >
                Print
              </Button>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
