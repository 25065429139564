import { useState, useEffect } from "react";
import {
  Box,
  Button,
  TextField,
  useMediaQuery,
  Typography,
  useTheme,
  CircularProgress,
} from "@mui/material";

import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

// import FlexBetween from "components/FlexBetween";

import { useDispatch, useSelector } from "react-redux";
import {
  useLoginMutation,
  useRegisterMutation,
} from "state/redux/auth/authApiSlice";
import { setCredentials } from "state/redux/auth/authSlice";

import { toast } from "react-toastify";

const registerSchema = yup.object({
  email: yup
    .string("Inserisci l'email")
    .email("Inserisci un'email valida")
    .required("L'Email è richiesta"),
  password: yup
    .string("Inserisci la password")
    .min(6, "La Password deve essere di almeno 6 caratteri")
    .required("La Password è richiesta"),
  passwordVerify: yup
    .string("Inserisci di nuovo la password")
    .oneOf([yup.ref("password"), null], "Le password non coincidono")
    .required("La verifica della password è richiesta"),
  nome: yup.string("Nome"),
  cognome: yup.string("Nome"),
});

const loginSchema = yup.object({
  email: yup
    .string()
    .email("Inserisci un'email valida")
    .required("L'Email è richiesta"),
  password: yup
    .string()
    .min(6, "La Password deve essere di almeno 6 caratteri")
    .required("La Password è richiesta"),
});

const passwordRecSchema = yup.object({
  email: yup
    .string()
    .email("Inserisci un'email valida")
    .required("L'Email è richiesta"),
});

const initialValuesRegister = {
  nome: "",
  cognome: "",
  email: "",
  password: "",
  passwordVerify: "",
};

const initialValuesLogin = {
  email: "",
  password: "",
};

const initialValuesPasswordRec = {
  email: "",
};

const Form = () => {
  const [pageType, setPageType] = useState("login");
  const { palette } = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const isLogin = pageType === "login";
  const isRegister = pageType === "register";
  const isPasswordRec = pageType === "passwordRec";

  const [login, { isLoading: isLoadingLogin }] = useLoginMutation();
  const [register, { isLoading: isLoadingRegister }] = useRegisterMutation();

  const { userInfo } = useSelector((state) => state.auth);

  useEffect(() => {
    if (userInfo) {
      navigate("/dash");
    }
  });

  const registerHandler = async (values) => {
    try {
      const res = await register({
        email: values.email,
        password: values.password,
        passwordVerify: values.passwordVerify,
        nome: values.nome,
        cognome: values.cognome,
      }).unwrap();
      dispatch(setCredentials({ ...res }));
      navigate("/");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const loginHandler = async (values) => {
    try {
      const res = await login({
        email: values.email,
        password: values.password,
      }).unwrap();
      dispatch(setCredentials({ ...res }));
      navigate("/dash");
    } catch (err) {
      toast.error(err?.data?.message || err.error, { position: "top-center" });
    }
  };

  const passwordRecHandler = async (values) => {
    try {
      //TODO implementare recupero password
      console.log(values);
    } catch (err) {
      toast.error(err?.data?.message || err.error, { position: "top-center" });
    }
  };

  const handleFormSubmit = async (values) => {
    if (isLogin) await loginHandler(values);
    if (isRegister) await registerHandler(values);
    if (isPasswordRec) await passwordRecHandler(values);
  };

  return (
    <Formik
      onSubmit={handleFormSubmit}
      initialValues={
        isLogin
          ? initialValuesLogin
          : isRegister
          ? initialValuesRegister
          : initialValuesPasswordRec
      }
      validationSchema={
        isLogin ? loginSchema : isRegister ? registerSchema : passwordRecSchema
      }
    >
      {({
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        resetForm,
      }) => (
        <form onSubmit={handleSubmit}>
          <Box
            display="grid"
            gap="30px"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            {isRegister && (
              <>
                <TextField
                  label="Nome"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  name="nome"
                  error={Boolean(touched.nome) && Boolean(errors.nome)}
                  helperText={touched.nome && errors.nome}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  label="Cognome"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.cognome}
                  name="cognome"
                  error={Boolean(touched.cognome) && Boolean(errors.cognome)}
                  helperText={touched.cognome && errors.cognome}
                  sx={{ gridColumn: "span 2" }}
                />
              </>
            )}

            <TextField
              label="Email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              name="email"
              error={Boolean(touched.email) && Boolean(errors.email)}
              helperText={touched.email && errors.email}
              sx={{ gridColumn: "span 4" }}
            />
            {(isLogin || isRegister) && (
              <TextField
                label="Password"
                type="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.password}
                name="password"
                error={Boolean(touched.password) && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                sx={{ gridColumn: isRegister ? "span 2" : "span 4" }}
              />
            )}

            {isRegister && (
              <TextField
                label="Verifica Password"
                type="password"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.passwordVerify}
                name="passwordVerify"
                error={
                  Boolean(touched.passwordVerify) &&
                  Boolean(errors.passwordVerify)
                }
                helperText={touched.passwordVerify && errors.passwordVerify}
                sx={{ gridColumn: "span 2" }}
              />
            )}
          </Box>

          {/* BUTTONS */}
          <Box>
            {(isLoadingRegister || isLoadingLogin) && <CircularProgress />}
            <Button
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              sx={{
                m: "2rem 0",
                p: "1rem",
                "&:hover": { color: palette.primary.main },
              }}
              disabled={isLoadingLogin || isLoadingRegister}
            >
              {isLogin
                ? "LOGIN"
                : isRegister
                ? "REGISTER"
                : "RECUPERA PASSWORD"}
            </Button>
            <Typography
              onClick={() => {
                setPageType(isLogin ? "register" : "login");
                resetForm();
              }}
              sx={{
                textDecoration: "underline",
                color: "primary",
                "&:hover": {
                  cursor: "pointer",
                  color: palette.primary.light,
                },
              }}
            >
              {isLogin
                ? "Non hai un account? Registrati."
                : "Hai già un account? Fai il Login."}
            </Typography>
            {isLogin && (
              <Typography
                onClick={() => {
                  setPageType("passwordRec");
                  resetForm();
                }}
                sx={{
                  textDecoration: "underline",
                  color: "primary",
                  "&:hover": {
                    cursor: "pointer",
                    color: palette.primary.light,
                  },
                }}
              >
                Password Dimenticata?
              </Typography>
            )}
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default Form;
