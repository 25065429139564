import {
  HomeOutlined,
  DeviceThermostat,
  TableRows,
  Router,
  Store,
} from "@mui/icons-material";

export const dashSideBarNavItems = [
  {
    text: "Dashboard",
    url: "",
    icon: <HomeOutlined />,
  },
  {
    text: "Logging",
    icon: null,
  },
  {
    text: "Log Dispositivi",
    url: "logdispo",
    icon: <TableRows />,
  },
  {
    text: "Assets",
    icon: null,
  },
  {
    text: "Sedi",
    url: "sedi",
    icon: <Store />,
  },
  {
    text: "Sniffers",
    url: "sniffer",
    icon: <Router />,
  },
  {
    text: "Dispositivi",
    url: "dispo",
    icon: <DeviceThermostat />,
  },
];
