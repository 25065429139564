import { apiSlice } from "state/redux/apiSlice.js";

export const logDispoApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLogDispo: builder.query({
      query: ({ dateStart, dateStop, dispos, sedi, duplicate }) => ({
        url: `/logdispo/getlogdispo`,
        method: "GET",
        params: {
          dateStart,
          dateStop,
          dispos,
          sedi,
          duplicate,
        },
      }),
      providesTags: ["LogDispo"],
    }),
  }),
});

export const { useGetLogDispoQuery } = logDispoApiSlice;
