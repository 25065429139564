import { apiSlice } from "state/redux/apiSlice.js";

export const dispoApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDispo: builder.query({
      query: () => `/dispo/getdispo`,
      providesTags: ["Dispo"],
    }),
    addDispo: builder.mutation({
      query: (data) => ({
        url: `/dispo/adddispo`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Dispo"],
    }),
    deleteDispo: builder.mutation({
      query: (data) => ({
        url: `/dispo/deletedispo`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Dispo"],
    }),
    updateDispo: builder.mutation({
      query: (data) => ({
        url: `/dispo/updatedispo`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Dispo"],
    }),
  }),
});

export const {
  useGetDispoQuery,
  useAddDispoMutation,
  useDeleteDispoMutation,
  useUpdateDispoMutation,
} = dispoApiSlice;
