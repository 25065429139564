import React, { useState } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Divider,
  Chip,
  IconButton,
} from "@mui/material";
import { Check, Close, Delete, Edit } from "@mui/icons-material";
import Header from "components/Header";
import {
  useGetDispoQuery,
  useDeleteDispoMutation,
} from "state/redux/dispo/dispoApiSlice";
import { green, red } from "@mui/material/colors";
import DispoFormDialog from "./DispoFormDialog";
import ConfirmDialog from "components/ConfirmDialog";
import FlexBetween from "components/FlexBetween";
import { toast } from "react-toastify";

const DispoCard = ({ dispo_info, handleOpenForm }) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const handleCloseConfirmDialog = ({ type }) => {
    if (type === "yes") deleteDispoHandler();
    setOpenConfirmDialog(false);
  };
  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const [deleteDispo, result] = useDeleteDispoMutation();

  const deleteDispoHandler = async (values) => {
    try {
      const res = await deleteDispo({ idsToDelete: dispo_info._id }).unwrap();
      toast.success(res.message, {
        position: "top-center",
        autoClose: 1000,
      });
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <Card
      sx={{
        backgroundImage: "none",
        backgroundColor: theme.palette.background.alt,
        borderRadius: "0.55rem",
      }}
    >
      <CardContent>
        <ConfirmDialog
          openConfirmDialog={openConfirmDialog}
          handleCloseConfirmDialog={handleCloseConfirmDialog}
        />
        <FlexBetween>
          <Typography
            sx={{ fontSize: 14 }}
            color={theme.palette.secondary[700]}
            gutterBottom
          >
            mac: {dispo_info.mac}
          </Typography>

          <FlexBetween>
            <IconButton aria-label="delete" onClick={handleOpenConfirmDialog}>
              <Delete />
            </IconButton>
            <IconButton
              aria-label="update"
              onClick={() =>
                handleOpenForm({
                  modeForm: "update",
                  dispo_info,
                })
              }
            >
              <Edit />
            </IconButton>
          </FlexBetween>
        </FlexBetween>

        <Typography variant="h5" component="div" gutterBottom>
          codifica: {dispo_info.codifica}
        </Typography>

        {dispo_info.enabled ? (
          <Chip
            icon={<Check color="inherit" />}
            label="Abilitato"
            sx={{ color: green[500] }}
            size="small"
          />
        ) : (
          <Chip
            icon={<Close color="inherit" />}
            label="Disabilitato"
            sx={{ color: red[500] }}
            size="small"
          />
        )}
      </CardContent>
      <CardActions>
        <Button
          variant="primary"
          size="small"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          Info
        </Button>
      </CardActions>
      <Collapse
        in={isExpanded}
        timeout="auto"
        unmountOnExit
        sx={{
          color: theme.palette.neutral[300],
        }}
      >
        <CardContent>
          <Typography>Sede</Typography>
          <Divider></Divider>
          <Typography>Codifica: {dispo_info.info_asset?.codifica}</Typography>
          <Typography sx={{ mb: 2 }}>
            Indirizzo: {dispo_info.info_asset?.indirizzo}
          </Typography>
          <Typography>Unità Misurata</Typography>
          <Divider></Divider>
          <Typography>
            Codifica: {dispo_info.info_asset?.unitaMisurate?.codifica}
          </Typography>
          <Typography>
            Ubicazione: {dispo_info.info_asset?.unitaMisurate?.ubicazione}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
};

const Dispo = () => {
  const { data, isLoading } = useGetDispoQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  const isNonMobile = useMediaQuery("(min-width: 1000px)");

  const [openForm, setOpenForm] = React.useState(false);
  const [modeForm, setModeForm] = React.useState();
  const [dispoForm, setDispoForm] = React.useState({});

  const handleOpenForm = ({ modeForm, dispo_info }) => {
    setDispoForm({ ...dispo_info });
    setModeForm(modeForm);
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="DISPOSITIVI" subtitle="Elenco dei dispositivi" />
      <DispoFormDialog
        openForm={openForm}
        handleCloseForm={handleCloseForm}
        modeForm={modeForm}
        dispoForm={dispoForm}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenForm({ modeForm: "add" })}
      >
        Aggiungi un Dispositivo
      </Button>

      {data || !isLoading ? (
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          justifyContent="space-between"
          rowGap="20px"
          columnGap="1.33%"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          {data.dispo.map((dispo) => (
            <DispoCard
              key={dispo._id}
              dispo_info={{ ...dispo }}
              handleOpenForm={handleOpenForm}
            />
          ))}
        </Box>
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
};

export default Dispo;
