import { Box, Typography, Container } from "@mui/material";
import React from "react";

function PublicHomePage() {
  return (
    <Box
      sx={{
        pt: 4,
        pb: 4,
      }}
    >
      <Container maxWidth="sm">
        <Typography component="h1" variant="h2" align="center" gutterBottom>
          PRB - Project
        </Typography>
        <Typography variant="h5" align="center" paragraph>
          Sistema di misura di temperatura
        </Typography>
      </Container>
    </Box>
  );
}

export default PublicHomePage;
