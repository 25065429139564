import React, { useState } from "react";
import {
  Box,
  useTheme,
  Stack,
  TextField,
  MenuItem,
  Button,
} from "@mui/material";
import { useGetLogDispoQuery } from "state/redux/logDispo/logDispoApiSlice";
import Header from "components/Header";
import { CheckCircle, Cancel } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import DataGridCustomToolbar from "components/DataGridCustomToolbar";
import { Formik } from "formik";
import * as yup from "yup";

export default function LogDispo() {
  const theme = useTheme();

  //dati per Backend
  const [dateStart, setDateStart] = useState();
  const [dateStop, , setDateStop] = useState();
  const [dispos, setDispos] = useState();
  const [sedi, setSedi] = useState();
  const [duplicate, setDuplicate] = useState();

  const { data, isLoading, isFetching } = useGetLogDispoQuery(
    {
      dateStart,
      dateStop,
      dispos,
      sedi,
      duplicate,
    },
    { refetchOnMountOrArgChange: true }
  );

  const columns = [
    {
      field: "dispo_codifica",
      headerName: "Codifica Dispo",
      flex: 0.3,
      type: "singleSelect",
      valueOptions: data && data.assetQueryList.uniqueDispo,
    },
    {
      field: "temp_calc",
      headerName: "Temperatura °C",
      flex: 0.2,
      type: "number",
      valueGetter: ({ value }) => value && Number(value).toFixed(1),
    },
    {
      field: "sede_codifica",
      headerName: "Codifica Sede",
      valueGetter: ({ row }) => {
        return row.sedeInfo?.codifica || " ";
      },
      flex: 0.2,
      type: "singleSelect",
      valueOptions: data && data.assetQueryList.uniqueSedi,
    },
    {
      field: "unita_codifica",
      headerName: "Codifica Caldaia",
      valueGetter: ({ row }) => {
        return row.sedeInfo?.unitaMisurate?.codifica || " ";
      },
      flex: 0.2,
      // type: "singleSelect",
      // valueOptions: data && data.assetQueryList.uniqueSedi,
    },
    {
      field: "ts_registrazione",
      headerName: "Time Stamp Registrazione",
      type: "dateTime",
      valueGetter: ({ value }) => value && new Date(value),
      flex: 0.3,
    },
    {
      field: "isReposted",
      headerName: "Ridiretto Bosch",
      flex: 0.3,
      type: "boolean",
      renderCell: (params) => {
        const isReposted = params.value;
        if (isReposted) {
          return <CheckCircle style={{ color: "green" }}></CheckCircle>;
        } else {
          return <Cancel style={{ color: "red" }}></Cancel>;
        }
      },
    },
  ];

  const filterSchema = yup.object({
    dateStart: yup.date(),
    dateStop: yup.date(),
    dispos: yup.string(),
    sedi: yup.string(),
    duplicate: yup.string(),
  });

  const initialValues = {
    dateStart: data?.assetQueryList.firstDate,
    dateStop: data?.assetQueryList.lastDate,
    dispos: "",
    disposList: data?.assetQueryList.uniqueDispo,
    sedi: "",
    sediList: data?.assetQueryList.uniqueSedi,
    duplicate: "not",
  };

  const handleFormSubmit = async (values) => {
    setDispos(values.dispos);
    console.log(values);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="LOG DISPO" subtitle="Log Dispositivi" />

      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={filterSchema}
      >
        {({
          values,
          isValid,
          dirty,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          resetForm,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit}>
            <Stack spacing={2} mt={2} mb={2}>
              <TextField
                label="Dispositivi"
                type="text"
                name="dispos"
                select
                onBlur={handleBlur}
                onChange={async (e) => {
                  const { value } = e.target;
                  await setFieldValue("dispos", value);
                  handleSubmit();
                }}
                value={values.dispos}
                error={Boolean(touched.dispos) && Boolean(errors.dispos)}
                helperText={touched.dispos && errors.dispos}
                fullWidth
              >
                <MenuItem value={""} key={"null_option"}>
                  <em>Tutti i dispositivi</em>
                </MenuItem>
                {data &&
                  data.assetQueryList.uniqueDispo.map((dispo, index) => (
                    <MenuItem value={dispo} key={index}>
                      {dispo}
                    </MenuItem>
                  ))}
              </TextField>
            </Stack>

            <Button
              type="submit"
              variant="contained"
              disabled={!(isValid && dirty)}
              sx={{
                m: 2,
                "&:hover": { color: theme.palette.primary.main },
              }}
            >
              Salva
            </Button>
            <Button
              onClick={() => {
                console.log("values: ", values);
                console.log("dirty: ", dirty);
                console.log("isValid: ", isValid);
              }}
              variant="contained"
              sx={{ m: 2 }}
            >
              Print
            </Button>
          </form>
        )}
      </Formik>
      {/* {data || !isLoading || !isFetching ? ( */}
      {data || !isFetching ? (
        <Box
          mt="40px"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: theme.palette.primary.light,
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: theme.palette.background.alt,
              color: theme.palette.secondary[100],
              borderTop: "none",
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${theme.palette.secondary[200]} !important`,
            },
          }}
        >
          <DataGrid
            loading={isFetching || !data}
            getRowId={(row) => row._id}
            rows={(data && data.logDispo) || []}
            columns={columns}
            slots={{ toolbar: DataGridCustomToolbar }}
          />
        </Box>
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
}

// export default LogDispo;
