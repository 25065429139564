import React, { useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

import DashNavBar from "components/dashNavBar";
import DashSideBar from "components/dashSideBar";
import Footer from "components/footer";

function DashLayout() {
  const isNonMobile = useMediaQuery("(min-width: 600px)");
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { userInfo } = useSelector((state) => state.auth);

  return (
    <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
      <DashSideBar
        userInfo={userInfo || {}}
        isNonMobile={isNonMobile}
        drawerWidth="250px"
        isSidebarOpen={isSidebarOpen}
        setIsSidebarOpen={setIsSidebarOpen}
      />
      <Box flexGrow={1}>
        <DashNavBar
          userInfo={userInfo || {}}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
        <Outlet />
        <Footer sx={{ mt: 3 }} />
      </Box>
    </Box>
  );
}

export default DashLayout;
