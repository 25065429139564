import React from "react";
import { createBrowserRouter } from "react-router-dom";

import PublicLayout from "layout/publicLayout";
import PublicHomePage from "pages/public/publicHomePage";

import AuthPage from "pages/auth/authPage";

import DashLayout from "layout/dashLayout";
import DashHomePage from "pages/dash/dashHomePage";
import LogDispo from "pages/dash/logDispo";
import Dispo from "pages/dash/dispo";
import Sedi from "pages/dash/sedi";

// import ErrorPage from "../pages/utility/ErrorPage";
// import NotFound from "../pages/utility/NotFound";

const router = createBrowserRouter([
  {
    path: "/",
    element: <PublicLayout />,
    // errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <PublicHomePage />,
      },
      {
        path: "auth",
        element: <AuthPage />,
      },
    ],
  },
  {
    path: "/dash",
    element: <DashLayout />,

    children: [
      {
        index: true,
        element: <DashHomePage />,
      },
      {
        path: "logdispo",
        element: <LogDispo />,
      },
      {
        path: "sedi",
        element: <Sedi />,
      },
      {
        path: "dispo",
        element: <Dispo />,
      },
    ],
  },
  {
    path: "*",
    // element: <NotFound />,
  },
]);

export default router;
