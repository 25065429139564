import * as React from "react";
import {
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Dialog,
  TextField,
  Button,
  Divider,
} from "@mui/material";
import { Delete, Add } from "@mui/icons-material";
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useTheme, Stack } from "@mui/material";

import { Formik, FieldArray, Field } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

import {
  useUpdateSedeMutation,
  useAddSedeMutation,
} from "state/redux/sede/sedeApiSlice";

import { toast } from "react-toastify";
import FlexBetween from "components/FlexBetween";

const addSedeSchema = yup.object({
  codifica: yup
    .string("Inserisci la codifica")
    .required("La Codifica è richiesta"),
  unitaMisurate: yup.array().of(
    yup.object().shape({
      codifica: yup.string().required("La Codifica è richiesta"),
      ubicazione: yup.string(),
    })
  ),
});

export default function DispoFormDialog({
  openForm,
  handleCloseForm,
  modeForm,
  sedeForm,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [updateSede, { isLoading: isLoadingUpdate }] = useUpdateSedeMutation();
  const [addSede, { isLoading: isLoadingAdd }] = useAddSedeMutation();

  const emptyUnitaMisurata = { codifica: "", ubicazione: "" };

  let initialValues = {};
  if (modeForm === "add") {
    initialValues = {
      codifica: "",
      indirizzo: "",
      unitaMisurate: [],
    };
  } else {
    initialValues = {
      _id: sedeForm._id,
      codifica: sedeForm.codifica,
      unitaMisurate: sedeForm.unitaMisurate ?? [emptyUnitaMisurata],
      delete: false,
    };
  }

  const handleAddSede = async (values) => {
    try {
      const res = await addSede({
        codifica: values.codifica,
        unitaMisurate: values.unitaMisurate,
      }).unwrap();
      toast.success(`sede ${res.sede.codifica} aggiunta`, {
        position: "top-center",
        autoClose: 1000,
      });
      navigate("/dash/sedi");
      handleCloseForm();
    } catch (err) {
      toast.error(err?.data?.message || err.error, { position: "top-center" });
    }
  };

  const handleUpdateSede = async (values) => {
    try {
      console.log(values);
      const res = await updateSede({
        _id: values._id,
        codifica: values.codifica,
        unitaMisurate: values.unitaMisurate,
      }).unwrap();
      toast.success(`sede ${res.sede.codifica} modificata`, {
        position: "top-center",
        autoClose: 1000,
      });
      handleCloseForm();
      navigate("/dash/sedi");
    } catch (err) {
      toast.error(err?.data?.message || err.error, { position: "top-center" });
    }
  };

  const handleFormSubmit = async (values) => {
    if (modeForm === "add") await handleAddSede(values);
    if (modeForm === "update") await handleUpdateSede(values);
  };

  return (
    <Dialog open={openForm} onClose={handleCloseForm} fullWidth>
      <DialogTitle
        style={{
          backgroundColor: theme.palette.background.alt,
        }}
      >
        {modeForm === "add" ? "Aggiungi una sede" : "Modifica la sede"}
      </DialogTitle>
      <DialogContent
        style={{
          backgroundColor: theme.palette.background.alt,
        }}
      >
        <Formik
          onSubmit={handleFormSubmit}
          initialValues={initialValues}
          validationSchema={addSedeSchema}
        >
          {({
            values,
            isValid,
            dirty,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            resetForm,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <Stack spacing={2} mt={2} mb={2}>
                <TextField
                  label="Codifica"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.codifica}
                  name="codifica"
                  error={Boolean(touched.codifica) && Boolean(errors.codifica)}
                  helperText={touched.codifica && errors.codifica}
                  fullWidth
                />
                <TextField
                  label="Indirizzo"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.indirizzo}
                  name="indirizzo"
                  error={
                    Boolean(touched.indirizzo) && Boolean(errors.indirizzo)
                  }
                  helperText={touched.indirizzo && errors.indirizzo}
                  fullWidth
                />
              </Stack>

              <Divider sx={{ mb: 1 }}>Unità da Misurare</Divider>

              <FieldArray
                name="unitaMisurate"
                render={({ remove, push }) => (
                  <Stack>
                    {values.unitaMisurate.map(
                      (unita, index) =>
                        !unita.delete && (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "stretch",
                              justifyContent: "space-between",
                              mb: 2,
                            }}
                            key={index}
                          >
                            <Field
                              name={`unitaMisurate[${index}].codifica`}
                              as={TextField}
                              label="Codifica"
                              error={
                                Boolean(
                                  touched.unitaMisurate?.[index]?.codifica
                                ) &&
                                Boolean(errors.unitaMisurate?.[index]?.codifica)
                              }
                              helperText={
                                touched.unitaMisurate?.[index]?.codifica &&
                                errors.unitaMisurate?.[index]?.codifica
                              }
                            />

                            <Field
                              name={`unitaMisurate.${index}.ubicazione`}
                              as={TextField}
                              label="Ubicazione"
                            />

                            <Button
                              type="button"
                              variant="contained"
                              onClick={() => {
                                const objToRemove = remove(index);
                                push({ ...objToRemove, delete: true });
                              }}
                            >
                              <Delete />
                            </Button>
                          </Box>
                        )
                    )}

                    <Button
                      type="button"
                      variant="contained"
                      onClick={() => {
                        push(emptyUnitaMisurata);
                      }}
                      startIcon={<Add />}
                    >
                      Aggiungi una unità da misurare
                    </Button>
                  </Stack>
                )}
              />

              <Divider sx={{ mb: 1, mt: 2 }}></Divider>

              <Button
                onClick={handleCloseForm}
                variant="contained"
                sx={{
                  m: 2,
                  "&:hover": { color: theme.palette.primary.main },
                }}
              >
                Annulla
              </Button>
              <Button
                type="submit"
                variant="contained"
                disabled={!(isValid && dirty)}
                sx={{
                  m: 2,
                  "&:hover": { color: theme.palette.primary.main },
                }}
              >
                Salva
              </Button>
              {/* <Button
                onClick={() => {
                  console.log("values: ", values);
                  console.log("dirty: ", dirty);
                  console.log("isValid: ", isValid);
                  console.log("errors: ", errors);
                  console.log("touched: ", touched);
                }}
                variant="contained"
                sx={{ m: 2 }}
              >
                Print
              </Button> */}
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}
