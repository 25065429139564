import React from "react";
import { Typography, Link } from "@mui/material";

function Footer(props) {
  return (
    <Typography variant="body2" align="center" {...props}>
      {"Copyright © "}
      <Link
        href="https://www.iotalab.it/"
        target="_blank"
        color="inherit"
        underline="hover"
      >
        Iotalab
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default Footer;
