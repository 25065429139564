// router import
import { RouterProvider } from "react-router-dom";
import router from "navigation/router.js";
/////

///
import { useSelector } from "react-redux";

/// mui import
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { themeSettings } from "theme/theme_yellow_purple";
////

//react import
import { useMemo } from "react";
///

// other imports///
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const mode = useSelector((state) => state.mode.mode);

  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return (
    <div className="app">
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
    </div>
  );
}

export default App;
