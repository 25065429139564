import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";

const REACT_APP_BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL || "http://localhost:3500";

const baseQuery = fetchBaseQuery({
  baseUrl: REACT_APP_BACKEND_URL,
  credentials: "include",
});

export const apiSlice = createApi({
  baseQuery,
  tagTypes: ["User", "Dispo", "Sedi", "LogDispo"],
  endpoints: (builder) => ({}),
});
