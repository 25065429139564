import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
import { useTheme } from "@mui/material";

export default function AlertDialog({
  openConfirmDialog,
  handleCloseConfirmDialog,
}) {
  const theme = useTheme();
  return (
    <Dialog
      open={openConfirmDialog}
      onClose={handleCloseConfirmDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {/* <DialogTitle id="alert-dialog-title">Stai Eliminando</DialogTitle> */}
      <DialogContent
        style={{
          backgroundColor: theme.palette.background.alt,
        }}
      >
        <DialogContentText id="alert-dialog-description">
          Confermi L'operazione?
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{
          backgroundColor: theme.palette.background.alt,
        }}
      >
        <Button
          onClick={() => handleCloseConfirmDialog({ type: "yes" })}
          variant="contained"
          sx={{
            m: 2,
            "&:hover": { color: theme.palette.primary.main },
          }}
        >
          SI
        </Button>
        <Button
          onClick={() => handleCloseConfirmDialog({ type: "no" })}
          variant="contained"
          sx={{
            m: 2,
            "&:hover": { color: theme.palette.primary.main },
          }}
          autoFocus
        >
          NO
        </Button>
      </DialogActions>
    </Dialog>
  );
}
