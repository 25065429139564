import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { apiSlice } from "state/redux/apiSlice.js";

import authReducer from "state/redux/auth/authSlice.js";
import modeReducer from "state/redux/mode/modeSlice.js";

const devToolsEnabled = process.env.NODE_ENV === "production" ? false : true;

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    mode: modeReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: devToolsEnabled,
});

setupListeners(store.dispatch);

export default store;
