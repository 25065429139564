import { apiSlice } from "state/redux/apiSlice.js";

export const sedeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSedi: builder.query({
      query: () => `/sedi/getsedi`,
      providesTags: ["Sedi"],
    }),
    addSede: builder.mutation({
      query: (data) => ({
        url: `/sedi/addsede`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Sedi"],
    }),
    deleteSede: builder.mutation({
      query: (data) => ({
        url: `/sedi/deletesede`,
        method: "DELETE",
        body: data,
      }),
      invalidatesTags: ["Sedi"],
    }),
    updateSede: builder.mutation({
      query: (data) => ({
        url: `/sedi/updatesede`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["Sedi"],
    }),
  }),
});

export const {
  useGetSediQuery,
  useAddSedeMutation,
  useDeleteSedeMutation,
  useUpdateSedeMutation,
} = sedeApiSlice;
