import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "components/footer";
import PublicNavBar from "components/publicNavBar";

function PublicLayout() {
  return (
    <>
      <PublicNavBar />
      <Outlet />
      <Footer sx={{ mt: 3 }} />
    </>
  );
}

export default PublicLayout;
