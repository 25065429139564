import React, { useState } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Button,
  Typography,
  useTheme,
  useMediaQuery,
  Divider,
  Chip,
  IconButton,
} from "@mui/material";
import { Check, Close, Delete, Edit } from "@mui/icons-material";
import Header from "components/Header";
import {
  useGetSediQuery,
  useDeleteSedeMutation,
} from "state/redux/sede/sedeApiSlice";
import { green, red } from "@mui/material/colors";
import SedeFormDialog from "./SedeFormDialog";
import ConfirmDialog from "components/ConfirmDialog";
import FlexBetween from "components/FlexBetween";
import { toast } from "react-toastify";

const SedeCard = ({ sede_info, handleOpenForm }) => {
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const handleCloseConfirmDialog = ({ type }) => {
    if (type === "yes") deleteSedeHandler();
    setOpenConfirmDialog(false);
  };
  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const [deleteSede, result] = useDeleteSedeMutation();

  const deleteSedeHandler = async (values) => {
    try {
      const res = await deleteSede({ idsToDelete: sede_info._id }).unwrap();
      toast.success(res.message, {
        position: "top-center",
        autoClose: 1000,
      });
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <Card
      sx={{
        backgroundImage: "none",
        backgroundColor: theme.palette.background.alt,
        borderRadius: "0.55rem",
      }}
    >
      <CardContent>
        <ConfirmDialog
          openConfirmDialog={openConfirmDialog}
          handleCloseConfirmDialog={handleCloseConfirmDialog}
        />
        <FlexBetween>
          <Typography>{""}</Typography>

          <FlexBetween>
            <IconButton aria-label="delete" onClick={handleOpenConfirmDialog}>
              <Delete />
            </IconButton>
            <IconButton
              aria-label="update"
              onClick={() =>
                handleOpenForm({
                  modeForm: "update",
                  sede_info,
                })
              }
            >
              <Edit />
            </IconButton>
          </FlexBetween>
        </FlexBetween>

        <Typography variant="h5" component="div" gutterBottom>
          codifica: {sede_info.codifica}
        </Typography>
      </CardContent>
      <CardActions>
        <Button
          variant="primary"
          size="small"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          Info
        </Button>
      </CardActions>
      <Collapse
        in={isExpanded}
        timeout="auto"
        unmountOnExit
        sx={{
          color: theme.palette.neutral[300],
        }}
      >
        <CardContent>
          {sede_info.unitaMisurate.length > 0 && (
            <>
              <Typography>Codifica Unità</Typography>
              <Divider></Divider>
              {sede_info.unitaMisurate.map((unita) => (
                <Typography key={unita._id}>{unita.codifica}</Typography>
              ))}
            </>
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
};

const Sedi = () => {
  const { data, isLoading } = useGetSediQuery(
    {},
    { refetchOnMountOrArgChange: true }
  );

  const isNonMobile = useMediaQuery("(min-width: 1000px)");

  const [openForm, setOpenForm] = React.useState(false);
  const [modeForm, setModeForm] = React.useState();
  const [sedeForm, setSedeForm] = React.useState({});

  const handleOpenForm = ({ modeForm, sede_info }) => {
    setSedeForm({ ...sede_info });
    setModeForm(modeForm);
    setOpenForm(true);
  };

  const handleCloseForm = () => {
    setOpenForm(false);
  };

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="SEDI" subtitle="Elenco delle sedi" />
      <SedeFormDialog
        openForm={openForm}
        handleCloseForm={handleCloseForm}
        modeForm={modeForm}
        sedeForm={sedeForm}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOpenForm({ modeForm: "add" })}
      >
        Aggiungi una sede
      </Button>

      {data || !isLoading ? (
        <Box
          mt="20px"
          display="grid"
          gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          justifyContent="space-between"
          rowGap="20px"
          columnGap="1.33%"
          sx={{
            "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
          }}
        >
          {data.map((sede) => {
            return (
              <SedeCard
                key={sede._id}
                sede_info={{ ...sede }}
                handleOpenForm={handleOpenForm}
              />
            );
          })}
        </Box>
      ) : (
        <>Loading...</>
      )}
    </Box>
  );
};

export default Sedi;
